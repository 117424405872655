define("discourse/plugins/custom-group-banner/discourse/templates/connectors/above-site-header/custom-group-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShowBanner}}
    <div class="custom-group-banner-t {{this.bannerClass}}">
      <div class="banner-message cooked">{{this.currentBanner.message}}</div>
      {{#if this.currentBanner.dismissable}}
      <div>
        <button type="button" class="btn btn-text btn-default btn-flat" {{on "click" this.dismissBanner}}>
          {{i18n "custom_group_banner.dismiss"}}
        </button>
        </div>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "SbB/nfdR",
    "block": "[[[41,[30,0,[\"shouldShowBanner\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"custom-group-banner-t \",[30,0,[\"bannerClass\"]]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"banner-message cooked\"],[12],[1,[30,0,[\"currentBanner\",\"message\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"currentBanner\",\"dismissable\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-text btn-default btn-flat\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"dismissBanner\"]]],null],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"custom_group_banner.dismiss\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"button\",\"on\",\"i18n\"]]",
    "moduleName": "discourse/plugins/custom-group-banner/discourse/templates/connectors/above-site-header/custom-group-banner.hbs",
    "isStrictMode": false
  });
});