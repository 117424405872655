define("discourse/plugins/custom-group-banner/discourse/controllers/admin-plugins-custom-group-banner", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "discourse/lib/ajax"], function (_exports, _controller, _object, _service, _tracking, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsCustomGroupBannerController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "messageBus", [_service.inject]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "banners", [_tracking.tracked], function () {
      return [];
    }))();
    #banners = (() => (dt7948.i(this, "banners"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "groups", [_tracking.tracked], function () {
      return [];
    }))();
    #groups = (() => (dt7948.i(this, "groups"), void 0))();
    constructor() {
      super(...arguments);
      this.banners = this._deserializeConfig();
      this.messageBus.subscribe("/site/banner", data => {
        if (data.event === "dismissals_cleared") {
          this.notifyPropertyChange("banners");
        }
      });
      this._loadGroups();
    }
    _generateUniqueId() {
      return `banner_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    }
    async _loadGroups() {
      try {
        const groups = await this.store.findAll('group');
        this.groups = groups.toArray();
      } catch (error) {}
    }
    get availableGroups() {
      return this.groups.map(group => ({
        id: group.name,
        name: group.full_name || group.display_name
      }));
    }
    get availableStyles() {
      return [{
        id: "info-banner",
        name: "Info"
      }, {
        id: "success-banner",
        name: "Success"
      }, {
        id: "danger-banner",
        name: "Danger"
      }];
    }
    _deserializeConfig() {
      const config = this.siteSettings.custom_group_banner_config;
      if (!config) {
        return [];
      }
      return config.split(/(?<!\\)\|/).map(entry => {
        const [id, group, message, dismissable, className] = entry.split(/(?<!\\),/).map(s => s.trim().replace(/\\([,|])/g, '$1'));
        return {
          id: id,
          group,
          message,
          dismissable: dismissable === "true",
          className
        };
      });
    }
    _serializeConfig() {
      return this.banners.filter(banner => banner.group && banner.message).map(banner => `${banner.id},${banner.group},${banner.message.replace(/([,|])/g, '\\$1')},${banner.dismissable},${banner.className}`).join("|");
    }
    async addBanner() {
      this.banners = [...this.banners, {
        id: this._generateUniqueId(),
        group: this.availableGroups[0]?.id,
        message: "",
        dismissable: true,
        className: this.availableStyles[0]?.id
      }];
      await this._updateSetting();
    }
    static #_5 = (() => dt7948.n(this.prototype, "addBanner", [_object.action]))();
    async removeBanner(index) {
      const bannerToRemove = this.banners[index];
      try {
        this.banners = this.banners.filter((_, i) => i !== index);
        await this._updateSetting();

        // Clear dismissals for this banner
        await (0, _ajax.ajax)("/admin/banner/clear_dismissals", {
          type: "DELETE",
          data: {
            banner_keys: [bannerToRemove.id]
          }
        });
      } catch (error) {
        this.banners = this._deserializeConfig();
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "removeBanner", [_object.action]))();
    async updateBanner(index, field, value) {
      const updatedBanners = [...this.banners];
      updatedBanners[index] = {
        ...updatedBanners[index],
        [field]: value
      };
      this.banners = updatedBanners;
      await this._updateSetting();
    }
    static #_7 = (() => dt7948.n(this.prototype, "updateBanner", [_object.action]))();
    updateBannerDismissable(index, event) {
      if (event.target !== undefined) {
        this.updateBanner(index, "dismissable", event.target.checked);
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "updateBannerDismissable", [_object.action]))();
    async _updateSetting() {
      try {
        await (0, _ajax.ajax)("/admin/site_settings/custom_group_banner_config", {
          type: "PUT",
          data: {
            custom_group_banner_config: this._serializeConfig()
          }
        });
      } catch (error) {
        throw error;
      }
    }
  }
  _exports.default = AdminPluginsCustomGroupBannerController;
});