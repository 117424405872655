define("discourse/plugins/custom-group-banner/discourse/connectors/above-site-header/custom-group-banner", ["exports", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "@ember/template", "discourse/lib/text"], function (_exports, _component, _service, _tracking, _object, _ajax, _template, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomGroupBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "messageBus", [_service.inject]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "banners", [_tracking.tracked], function () {
      return [];
    }))();
    #banners = (() => (dt7948.i(this, "banners"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "localDismissedBanners", [_tracking.tracked], function () {
      return new Set();
    }))();
    #localDismissedBanners = (() => (dt7948.i(this, "localDismissedBanners"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "renderedMessages", [_tracking.tracked], function () {
      return new Map();
    }))();
    #renderedMessages = (() => (dt7948.i(this, "renderedMessages"), void 0))();
    constructor() {
      super(...arguments);
      this.banners = this._loadBanners();
      this._renderMessages();
      // Initialize localDismissedBanners with already dismissed banners
      if (this.currentUser?.dismissed_banners) {
        this.currentUser.dismissed_banners.forEach(key => this.localDismissedBanners.add(key));
      }
      // Subscribe to MessageBus for real-time updates
      this.messageBus.subscribe("/site/banner", data => {
        if (data.event === "dismissals_cleared") {
          // Remove the cleared banner keys from local state
          data.banner_keys.forEach(key => {
            this.localDismissedBanners.delete(key);
            if (this.currentUser?.dismissed_banners) {
              this.currentUser.dismissed_banners.removeObject(key);
            }
          });
          // Force a refresh of the component
          this.banners = this._loadBanners();
          this._renderMessages();
        }
      });
    }
    async _renderMessages() {
      const renderedMessages = new Map();
      for (const banner of this.banners) {
        if (banner.message) {
          try {
            const cooked = await (0, _text.cook)(banner.message);
            renderedMessages.set(banner.id, (0, _template.htmlSafe)(cooked));
          } catch (e) {
            renderedMessages.set(banner.id, (0, _template.htmlSafe)(banner.message));
          }
        }
      }
      this.renderedMessages = renderedMessages;
    }
    _loadBanners() {
      const config = this.siteSettings.custom_group_banner_config;
      if (!config) {
        return [];
      }
      return config.split("|").map(entry => {
        try {
          const [id, group, message, dismissable, className] = entry.split(",").map(s => s.trim());
          return {
            id,
            group,
            message,
            dismissable: dismissable === "true",
            className
          };
        } catch (e) {
          return null;
        }
      }).filter(banner => banner && banner.id && banner.group && banner.message);
    }
    get currentBanner() {
      const {
        currentUser
      } = this;
      if (!currentUser) {
        return null;
      }
      const userGroups = currentUser.groups.map(g => g.name);
      const cbanner = this.banners.find(banner => {
        return userGroups.includes(banner.group) && (!banner.dismissable || !this.localDismissedBanners.has(banner.id));
      });
      if (cbanner) {
        const renderedMessage = this.renderedMessages.get(cbanner.id);
        return {
          ...cbanner,
          message: renderedMessage || cbanner.message
        };
      }
      return null;
    }
    _getBannerKey(banner) {
      return `${banner.group}-${banner.message}`;
    }
    get shouldShowBanner() {
      return !!this.currentBanner;
    }
    get bannerClass() {
      return this.currentBanner?.className || "";
    }
    async dismissBanner() {
      const banner = this.currentBanner;
      if (!banner?.dismissable) {
        return;
      }

      // Update local state immediately
      this.localDismissedBanners = new Set([...this.localDismissedBanners, banner.id]);
      try {
        await (0, _ajax.ajax)("/banner/dismiss", {
          type: "POST",
          data: {
            banner_key: banner.id
          }
        });

        // Ensure dismissed_banners is initialized as an array
        if (!Array.isArray(this.currentUser.dismissed_banners)) {
          this.currentUser.set("dismissed_banners", []);
        }

        // Update the currentUser's dismissed_banners
        this.currentUser.dismissed_banners.pushObject(banner.id);
      } catch (error) {
        // If the server request fails, revert the local state
        this.localDismissedBanners.delete(banner.id);
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "dismissBanner", [_object.action]))();
  }
  _exports.default = CustomGroupBanner;
});